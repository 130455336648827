import React, { FC } from "react";
import { Controller } from "react-hook-form";

import "react-datepicker/dist/react-datepicker.css";

import DP from "react-datepicker";
import { RegisterOptions } from "react-hook-form/dist/types/validator";

const DatePicker: FC<{
  name: string;
  label: string;
  control: any; // todo
  errors: any; // todo // can remove if default values always be set
  options: RegisterOptions;
}> = ({ name, label, control, options, errors }) => {
  return (
    <>
      <label className="form-control w-full block">
        <div className="label">
          <span className="label-text">{label}</span>
          <span className="label-text-alt text-error">
            {errors[name] ? errors[name].message : ""}
          </span>
        </div>
        <Controller
          rules={options}
          control={control}
          name={name}
          render={({ field }) => (
            <DP
              placeholderText={label}
              onChange={(date: Date) => {
                field.onChange(date);
              }}
              selected={field.value}
              showTimeSelect
              dateFormat="Pp"
            />
          )}
        />
      </label>
    </>
  );
};

export default DatePicker;

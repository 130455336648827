import CategoryForm from "../category-form/category-form";
import React from "react";
import { useCategoriesControllerCreateMutation } from "../../../../rtk/codegen/core-api";
import { SubmitHandler, useForm } from "react-hook-form";
import { IFormInput } from "../category-form/interface/interfaces";

const CategoryPost = () => {
  const [categoriesControllerCreate, { isLoading, data, error }] =
    useCategoriesControllerCreateMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = async (values) => {
    await categoriesControllerCreate({
      createCategoryDto: {
        title: values.title,
      },
    });
    reset();
  };

  return (
    <CategoryForm
      isLoading={isLoading}
      loading={false}
      error={error}
      data={data}
      onSubmit={handleSubmit(onSubmit)}
      disabled={!isDirty}
      register={register}
      errors={errors}
    />
  );
};

export default CategoryPost;

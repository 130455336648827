import { DateRangePicker } from "../../../../components/aria-starter/DateRangePicker";
import { parseDate } from "@internationalized/date";
import React from "react";
import { useNotesControllerFindAllQuery } from "../../../../rtk/codegen/core-api";
import { useStartEndDateContext } from "../../contexts/start-end-date-context";
import Card from "../../../../components/card/card";
import { ActivityPeriodEnum } from "./enums/enums";
import { activityCalendarTranslationMapper } from "./utils/translation-mapper";
import { getActivityPeriodByEnum } from "./utils/get-activity-period-by-enum";

const ActivityCalendar = () => {
  const { startDate, endDate, setStartDate, setEndDate } =
    useStartEndDateContext();
  const { data, isLoading, error } = useNotesControllerFindAllQuery({
    startDate,
    endDate,
    // pageSize: 115,
    // page: 1,
    // search: "gg",
  });

  const handleChangeRange = (activityPeriod: ActivityPeriodEnum) => {
    const { startDate, endDate } = getActivityPeriodByEnum(activityPeriod);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  return (
    <Card title={"Activity calendar"}>
      <div className="prose mb-2">
        <div>
          {/*{startDate}-{endDate}*/}
          Notes for the selected period: <b>{data?.data?.length}</b>
        </div>
      </div>
      <div className={""}>
        <div className={""}>
          <DateRangePicker
            isReadOnly
            label="Date Range"
            value={{
              start: parseDate(startDate),
              end: parseDate(endDate),
            }}
            onChange={(val) => {
              setStartDate(val.start.toString());
              setEndDate(val.end.toString());
            }}
          />
        </div>
        <div className={"mt-3"}>
          <div className="flex flex-row flex-wrap gap-2">
            {Object.keys(ActivityPeriodEnum).map((activityPeriod: string) => {
              const activityPeriodByEnum = getActivityPeriodByEnum(
                activityPeriod as ActivityPeriodEnum,
              );

              return (
                <button
                  key={activityPeriod}
                  onClick={() =>
                    handleChangeRange(activityPeriod as ActivityPeriodEnum)
                  }
                  className={`btn btn-xs ${
                    activityPeriodByEnum.startDate === startDate &&
                    activityPeriodByEnum.endDate === endDate
                      ? "btn-primary"
                      : ""
                  }`}
                >
                  {
                    activityCalendarTranslationMapper[
                      activityPeriod as ActivityPeriodEnum
                    ]
                  }
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ActivityCalendar;

import React from "react";

export const Footer = () => {
  return (
    <footer className="mt-5 py-4 bg-white text-gray-400">
      <div className="px-2 md:px-0 md:w-3/4 mx-auto  flex items-center gap-2">
        <p className="text-sm">
          © 2024 ProgressViz - Visualize your progress.{" "}
          <a
            target={"_blank"}
            href={"https://progressviz.com/privacy-policy.html"}
            className="link link-primary link-hover"
          >
            Policy privacy
          </a>
          &nbsp;|&nbsp;
          <a
            href="mailto:progressvizcom@gmail.com"
            className="link link-primary link-hover"
          >
            Contact
          </a>
        </p>
      </div>
    </footer>
  );
};

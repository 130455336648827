import Card from "../../../../components/card/card";
import React from "react";
import { useCategoriesControllerFindAllQuery } from "../../../../rtk/codegen/core-api";
import Select from "../../../../components/form/select";
import { useCategoriesContext } from "../../contexts/categories-context";
import LoaderSpinner from "../../../../components/loader-spinner/loader-spinner";

const FiltersCategoriesNotes = () => {
  const { data: dataCategories, isLoading: isLoadingCategories } =
    useCategoriesControllerFindAllQuery();
  const { register } = useCategoriesContext();

  return (
    <Card title={"Filter by category"}>
      {isLoadingCategories ? (
        <LoaderSpinner />
      ) : (
        <Select
          label={"Categories"}
          name={"categories"}
          register={register}
          selectOptions={
            dataCategories?.map((el) => ({
              title: el.title,
              value: el.id.toString(),
            })) || []
          }
        />
      )}
    </Card>
  );
};

export default FiltersCategoriesNotes;

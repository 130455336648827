import React, { FC } from "react";

const Select: FC<{
  name: string;
  label: string;
  register: any; // todo
  selectOptions: { value: string; title: string }[];
}> = ({ name, register, label, selectOptions }) => {
  return (
    <>
      <div className="label">
        <span className="label-text">{label}</span>
      </div>
      <select
        {...register(name)}
        multiple
        className="select select-bordered w-full mb-2 block"
      >
        {selectOptions?.map((el) => (
          <option key={el.value} value={el.value}>
            {el.title}
          </option>
        ))}
      </select>
    </>
  );
};

export default Select;

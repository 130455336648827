import React from "react";
import { Layout } from "../../components/layout/layout";
import { useCategoriesControllerFindAllQuery } from "../../rtk/codegen/core-api";
import LoaderSpinner from "../../components/loader-spinner/loader-spinner";
import TableCategories from "./components/table-categories/table-categories";
import DeleteAllCategories from "./components/delete-all-categories/delete-all-categories";
import CategoryPost from "./components/category-post/category-post";

export const GetCategoriesPage: React.FC = () => {
  const { data, isLoading, error } = useCategoriesControllerFindAllQuery();

  return (
    <Layout>
      <>
        {isLoading && <LoaderSpinner />}
        {data && (
          <div className={"xl:flex xl:flex-row xl:space-x-6"}>
            <div className={"xl:w-3/4"}>
              <TableCategories />
            </div>
            <div className={"xl:w-1/4 xl:sticky xl:top-0 h-full"}>
              <CategoryPost />
              <DeleteAllCategories />
            </div>
          </div>
        )}
      </>
    </Layout>
  );
};

export enum RoutesEnum {
  notes = "/notes",
  categories = "/categories",
  notesPost = "/notes/new",
  callback = "/callback",
  login = "/auth/login",
  home = "/",
}

export enum RouteNamesEnum {
  notes = "Notes",
  categories = "Categories",
  notesPost = "New note",
}

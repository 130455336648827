// @ts-nocheck
// https://redux-toolkit.js.org/rtk-query/usage/error-handling#handling-errors-at-a-macro-level

import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";

const ignoredEndpointsLogAlerts: string[] = []; // 'fetchSomething1', 'fetchSomething2'
const __DEV__ = true;

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (
      isRejectedWithValue(action) &&
      !ignoredEndpointsLogAlerts.includes(action.meta.arg.endpointName)
    ) {
      alert(
        __DEV__
          ? `${JSON.stringify(action.payload)}\n${JSON.stringify(
              action.meta.arg.queryCacheKey,
            )}`
          : `API Error: ${action.meta.arg.endpointName}`,
      );
    }
    return next(action);
  };

import { emptySplitApi as api } from "./create-api";
export const addTagTypes = ["notes", "users", "categories", "import"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      appControllerGetHello: build.query<
        AppControllerGetHelloApiResponse,
        AppControllerGetHelloApiArg
      >({
        query: () => ({ url: `/api` }),
      }),
      notesControllerCreate: build.mutation<
        NotesControllerCreateApiResponse,
        NotesControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/notes`,
          method: "POST",
          body: queryArg.createNoteDto,
        }),
        invalidatesTags: ["notes"],
      }),
      notesControllerFindAll: build.query<
        NotesControllerFindAllApiResponse,
        NotesControllerFindAllApiArg
      >({
        query: (queryArg) => ({
          url: `/api/notes`,
          params: {
            startDate: queryArg.startDate,
            endDate: queryArg.endDate,
            search: queryArg.search,
            page: queryArg.page,
            pageSize: queryArg.pageSize,
            categories: queryArg.categories,
          },
        }),
        providesTags: ["notes"],
      }),
      notesControllerRemoveAll: build.mutation<
        NotesControllerRemoveAllApiResponse,
        NotesControllerRemoveAllApiArg
      >({
        query: () => ({ url: `/api/notes`, method: "DELETE" }),
        invalidatesTags: ["notes"],
      }),
      notesControllerFindOne: build.query<
        NotesControllerFindOneApiResponse,
        NotesControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/api/notes/${queryArg.id}` }),
        providesTags: ["notes"],
      }),
      notesControllerUpdate: build.mutation<
        NotesControllerUpdateApiResponse,
        NotesControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/notes/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.updateNoteDto,
        }),
        invalidatesTags: ["notes"],
      }),
      notesControllerRemove: build.mutation<
        NotesControllerRemoveApiResponse,
        NotesControllerRemoveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/notes/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["notes"],
      }),
      usersControllerCreateUser: build.mutation<
        UsersControllerCreateUserApiResponse,
        UsersControllerCreateUserApiArg
      >({
        query: () => ({ url: `/api/users`, method: "POST" }),
        invalidatesTags: ["users"],
      }),
      categoriesControllerCreate: build.mutation<
        CategoriesControllerCreateApiResponse,
        CategoriesControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/categories`,
          method: "POST",
          body: queryArg.createCategoryDto,
        }),
        invalidatesTags: ["categories"],
      }),
      categoriesControllerFindAll: build.query<
        CategoriesControllerFindAllApiResponse,
        CategoriesControllerFindAllApiArg
      >({
        query: () => ({ url: `/api/categories` }),
        providesTags: ["categories"],
      }),
      categoriesControllerRemoveAll: build.mutation<
        CategoriesControllerRemoveAllApiResponse,
        CategoriesControllerRemoveAllApiArg
      >({
        query: () => ({ url: `/api/categories`, method: "DELETE" }),
        invalidatesTags: ["categories", "notes"],
      }),
      categoriesControllerFindOne: build.query<
        CategoriesControllerFindOneApiResponse,
        CategoriesControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/api/categories/${queryArg.id}` }),
        providesTags: ["categories"],
      }),
      categoriesControllerUpdate: build.mutation<
        CategoriesControllerUpdateApiResponse,
        CategoriesControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/categories/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.updateCategoryDto,
        }),
        invalidatesTags: ["categories", "notes"],
      }),
      categoriesControllerRemove: build.mutation<
        CategoriesControllerRemoveApiResponse,
        CategoriesControllerRemoveApiArg
      >({
        query: (queryArg) => ({
          url: `/api/categories/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["categories"],
      }),
      importControllerImportData: build.mutation<
        ImportControllerImportDataApiResponse,
        ImportControllerImportDataApiArg
      >({
        query: (queryArg) => ({
          url: `/api/import/import`,
          method: "POST",
          body: queryArg.createImportDto,
        }),
        invalidatesTags: ["import", "notes", "categories"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as coreApi };
export type AppControllerGetHelloApiResponse = unknown;
export type AppControllerGetHelloApiArg = void;
export type NotesControllerCreateApiResponse = /** status 201  */ NoteEntity;
export type NotesControllerCreateApiArg = {
  createNoteDto: CreateNoteDto;
};
export type NotesControllerFindAllApiResponse =
  /** status 200 Returns a list of notes with metadata */ {
    data?: NoteEntity[];
    meta?: {
      total?: number;
      lastPage?: number;
      currentPage?: number;
      perPage?: number;
      prev?: number;
      next?: number;
    };
  };
export type NotesControllerFindAllApiArg = {
  startDate?: string;
  endDate?: string;
  search?: string;
  page?: number;
  pageSize?: number;
  categories?: number[];
};
export type NotesControllerRemoveAllApiResponse = /** status 200  */ NoteEntity;
export type NotesControllerRemoveAllApiArg = void;
export type NotesControllerFindOneApiResponse = /** status 200  */ NoteEntity;
export type NotesControllerFindOneApiArg = {
  id: number;
};
export type NotesControllerUpdateApiResponse = /** status 201  */ NoteEntity;
export type NotesControllerUpdateApiArg = {
  id: number;
  updateNoteDto: UpdateNoteDto;
};
export type NotesControllerRemoveApiResponse = /** status 200  */ NoteEntity;
export type NotesControllerRemoveApiArg = {
  id: number;
};
export type UsersControllerCreateUserApiResponse = unknown;
export type UsersControllerCreateUserApiArg = void;
export type CategoriesControllerCreateApiResponse =
  /** status 201  */ CategoryEntity;
export type CategoriesControllerCreateApiArg = {
  createCategoryDto: CreateCategoryDto;
};
export type CategoriesControllerFindAllApiResponse =
  /** status 200  */ CategoryEntity[];
export type CategoriesControllerFindAllApiArg = void;
export type CategoriesControllerRemoveAllApiResponse =
  /** status 200  */ CategoryEntity;
export type CategoriesControllerRemoveAllApiArg = void;
export type CategoriesControllerFindOneApiResponse =
  /** status 200  */ CategoryEntity;
export type CategoriesControllerFindOneApiArg = {
  id: number;
};
export type CategoriesControllerUpdateApiResponse =
  /** status 201  */ CategoryEntity;
export type CategoriesControllerUpdateApiArg = {
  id: number;
  updateCategoryDto: UpdateCategoryDto;
};
export type CategoriesControllerRemoveApiResponse =
  /** status 200  */ CategoryEntity;
export type CategoriesControllerRemoveApiArg = {
  id: number;
};
export type ImportControllerImportDataApiResponse = unknown;
export type ImportControllerImportDataApiArg = {
  createImportDto: CreateImportDto;
};
export type UserEntity = {
  id: number;
  createdAt: string;
  updatedAt: string;
  sub: string;
};
export type CategoryEntity = {
  createdAt: string;
  id: number;
  title: string;
  updatedAt: string;
  userId: number;
  user: UserEntity;
};
export type NoteEntity = {
  id: number;
  title: string;
  description?: string | null;
  body: string;
  published: boolean;
  createdAt: string;
  updatedAt: string;
  userId: number;
  user?: UserEntity;
  categories: CategoryEntity[];
  startDate: string;
};
export type CreateNoteDto = {
  title: string;
  description?: string;
  body: string;
  published?: boolean;
  startDate: string;
  categories: number[];
};
export type UpdateNoteDto = {
  title?: string;
  description?: string;
  body?: string;
  published?: boolean;
  startDate?: string;
  categories?: number[];
};
export type CreateCategoryDto = {
  title: string;
};
export type UpdateCategoryDto = {
  title?: string;
};
export type CreateImportDto = {
  importedData: string;
};
export const {
  useAppControllerGetHelloQuery,
  useNotesControllerCreateMutation,
  useNotesControllerFindAllQuery,
  useNotesControllerRemoveAllMutation,
  useNotesControllerFindOneQuery,
  useNotesControllerUpdateMutation,
  useNotesControllerRemoveMutation,
  useUsersControllerCreateUserMutation,
  useCategoriesControllerCreateMutation,
  useCategoriesControllerFindAllQuery,
  useCategoriesControllerRemoveAllMutation,
  useCategoriesControllerFindOneQuery,
  useCategoriesControllerUpdateMutation,
  useCategoriesControllerRemoveMutation,
  useImportControllerImportDataMutation,
} = injectedRtkApi;

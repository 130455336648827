import Card from "../../../../components/card/card";
import { Link } from "react-router-dom";
import { getCategoriesRouteById } from "../../../../utils/get-categories-route-by-id";
import React from "react";
import {
  useCategoriesControllerFindAllQuery,
  useCategoriesControllerRemoveMutation,
} from "../../../../rtk/codegen/core-api";
import useLoadingStates from "../../../get-notes-page/components/table-notes/use-loading-states";
import DButton from "../../../../components/d-button/d-button";

const TableCategories = () => {
  const { data, isLoading, error } = useCategoriesControllerFindAllQuery();
  const [categoriesControllerRemove, { isLoading: isLoadingRemove }] =
    useCategoriesControllerRemoveMutation();

  const { loadingStates, handleRemove } = useLoadingStates();

  return (
    <Card title={"Categories"}>
      <div className="overflow-x-auto">
        <table className="table table-xs">
          <thead>
            <tr>
              {/*<th>#</th>*/}
              <th className="flex-1">Title</th>
              <th className="w-auto text-right"></th>
            </tr>
          </thead>
          <tbody>
            {data?.map((el) => (
              <tr key={el.id}>
                {/*<th>{el.id}</th>*/}
                <td className="flex-1">
                  <Link
                    className="link link-primary"
                    to={getCategoriesRouteById(el.id)}
                  >
                    {el.title}
                  </Link>
                </td>
                <td className="w-auto text-right">
                  <DButton
                    hideSpinner
                    className={"btn btn-error btn-xs"}
                    disabled={loadingStates[el.id]}
                    isLoading={loadingStates[el.id]}
                    name={"Delete"}
                    onClick={() =>
                      handleRemove(Number(el.id), categoriesControllerRemove)
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default TableCategories;

import React from "react";
import { Layout } from "../../components/layout/layout";
import {
  useCategoriesControllerFindAllQuery,
  useNotesControllerCreateMutation,
} from "../../rtk/codegen/core-api";
import { SubmitHandler, useForm } from "react-hook-form";
import NoteForm from "./components/note-form";
import { IFormInput } from "./interfaces/interfaces";

export const NotesPostPage: React.FC = () => {
  const [notesControllerCreate, { isLoading: isLoadingCreate, error, data }] =
    useNotesControllerCreateMutation();

  const { data: dataCategories, isLoading: isLoadingCategories } =
    useCategoriesControllerFindAllQuery();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    control,
  } = useForm<IFormInput>({
    mode: "onSubmit",
    defaultValues: {
      startDate: new Date(),
    },
  });
  const onSubmit: SubmitHandler<IFormInput> = async (values) => {
    await notesControllerCreate({
      createNoteDto: {
        startDate: values.startDate.toISOString(),
        title: values.title,
        body: values.body,
        categories: values.categories.map((el) => Number(el)),
      },
    });
    reset();
  };

  return (
    <Layout small>
      <NoteForm
        isLoading={isLoadingCreate}
        loading={isLoadingCategories}
        onSubmit={handleSubmit(onSubmit)}
        errors={errors}
        register={register}
        dataCategories={dataCategories}
        control={control}
        data={data}
        error={error}
        disabled={!isDirty}
      />
    </Layout>
  );
};

import MyResponsiveBar from "../../../../components/my-responsive-bar/my-responsive-bar";
import React, { useEffect, useState } from "react";
import { useNotesControllerFindAllQuery } from "../../../../rtk/codegen/core-api";
import { useStartEndDateContext } from "../../contexts/start-end-date-context";
import NoDataPlaceholder from "../no-data-placeholder/no-data-placeholder";
import { NO_CATEGORY } from "../../../../constants/no-category";
import dayjs from "dayjs";
import { dateFormat } from "../../../../constants/date-format";

const ProgressTimeline = () => {
  const [tabIndexG, setTabIndexG] = useState(1);
  const { startDate, endDate } = useStartEndDateContext();
  const { data, isLoading, error } = useNotesControllerFindAllQuery({
    startDate,
    endDate,
    // pageSize: 115,
    // page: 1,
    // search: "gg",
  });

  // TODO: get barData from context (Timeline, Summary)
  const barData: any[] = getMonthRange(startDate, endDate, tabIndexG === 0);
  let barDataKeys: string[] = [];
  if (data) {
    data?.data?.forEach((note) => {
      note.categories.forEach((category) => {
        let formattedDate = note.startDate.split("T")[0];
        if (tabIndexG === 1) {
          formattedDate = formattedDate.slice(0, 7); // 2023-02
        }
        let index = barData.findIndex((el) => el.dates === formattedDate);
        if (index === -1) {
          index = barData.length;
          barData[index] = {
            dates: formattedDate,
          };
        }
        if (category.title in barData[index]) {
          barData[index][category.title]++;
        } else {
          barData[index][category.title] = 1;

          if (!barDataKeys.includes(category.title)) {
            barDataKeys.push(category.title);
          }
        }
      });
      if (!note.categories.length) {
        let formattedDate = note.startDate.split("T")[0];
        if (tabIndexG === 1) {
          formattedDate = formattedDate.slice(0, 7); // 2023-02
        }
        let index = barData.findIndex((el) => el.dates === formattedDate);
        if (index === -1) {
          index = barData.length;
          barData[index] = {
            dates: formattedDate,
          };
        }
        if (NO_CATEGORY in barData[index]) {
          barData[index][NO_CATEGORY]++;
        } else {
          barData[index][NO_CATEGORY] = 1;

          if (!barDataKeys.includes(NO_CATEGORY)) {
            barDataKeys.push(NO_CATEGORY);
          }
        }
      }
    });
  }
  barData.sort((a, b) => a.dates.localeCompare(b.dates));

  const showPlaceholder = data?.data?.length === 0;

  useEffect(() => {
    if (dataForMoreThat10days(startDate, endDate) && tabIndexG === 0) {
      setTabIndexG(1);
    }
  }, [startDate, endDate, tabIndexG]);

  return (
    <div className="card bg-base-100 mt-5">
      <div className="card-body">
        <div className="flex flex-row">
          <div className="prose">
            <h2 className="m-0">Progress timeline</h2>
          </div>
          {!showPlaceholder && (
            <div className="ml-auto flex">
              <div role="tablist" className="tabs tabs-bordered">
                <a
                  role="tab"
                  className={`tab 
                  ${tabIndexG === 0 ? "tab-active" : ""} ${
                    dataForMoreThat10days(startDate, endDate)
                      ? "tab-disabled"
                      : ""
                  }`}
                  onClick={() => {
                    if (!dataForMoreThat10days(startDate, endDate)) {
                      setTabIndexG(0);
                    }
                  }}
                >
                  Daily
                </a>
                <a
                  role="tab"
                  className={`tab ${tabIndexG === 1 ? "tab-active" : ""}`}
                  onClick={() => {
                    setTabIndexG(1);
                  }}
                >
                  Monthly
                </a>
              </div>
            </div>
          )}
        </div>

        <div className={"relative"} style={{ height: "400px" }}>
          {showPlaceholder ? (
            <NoDataPlaceholder />
          ) : (
            <MyResponsiveBar keys={barDataKeys} data={barData} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProgressTimeline;

function getMonthRange(startDate: string, endDate: string, day = false) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const range = [];

  let currentDate = new Date(start);
  while (currentDate <= end) {
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    if (day) {
      const dayOfMonth = currentDate.getDate().toString().padStart(2, "0");
      range.push({ dates: `${year}-${month}-${dayOfMonth}` });
      currentDate.setDate(currentDate.getDate() + 1); // Increment by day
    } else {
      range.push({ dates: `${year}-${month}` });
      currentDate.setMonth(currentDate.getMonth() + 1); // Increment by month
    }
  }

  return range;
}

const format = dateFormat["YYYY-MM-DD"];

const dataForMoreThat10days = (startDate: string, endDate: string) => {
  const diffInDays = dayjs(endDate, format).diff(
    dayjs(startDate, format),
    "day",
  );
  return diffInDays > 10;
};

import MyResponsivePie from "../../../../components/my-responsive-pie/my-responsive-pie";
import React, { useState } from "react";
import { useStartEndDateContext } from "../../contexts/start-end-date-context";
import { useNotesControllerFindAllQuery } from "../../../../rtk/codegen/core-api";
import { Car } from "lucide-react";
import Card from "../../../../components/card/card";
import NoDataPlaceholder from "../no-data-placeholder/no-data-placeholder";
import { NO_CATEGORY } from "../../../../constants/no-category";

const ProgressSummary = () => {
  const [tabIndexG, setTabIndexG] = useState(1);
  const { startDate, endDate } = useStartEndDateContext();

  const { data, isLoading, error } = useNotesControllerFindAllQuery({
    startDate,
    endDate,
    // pageSize: 115,
    // page: 1,
    // search: "gg",
  });

  // TODO: get barData from context (Timeline, Summary)
  const barData: any[] = [];
  let barDataKeys: string[] = [];
  if (data) {
    data?.data?.forEach((note) => {
      note.categories.forEach((category) => {
        let formattedDate = note.startDate.split("T")[0];
        if (tabIndexG === 1) {
          formattedDate = formattedDate.slice(0, 7); // 2023-02
        }
        let index = barData.findIndex((el) => el.dates === formattedDate);
        if (index === -1) {
          index = barData.length;
          barData[index] = {
            dates: formattedDate,
          };
        }
        if (category.title in barData[index]) {
          barData[index][category.title]++;
        } else {
          barData[index][category.title] = 1;

          if (!barDataKeys.includes(category.title)) {
            barDataKeys.push(category.title);
          }
        }
      });
      if (!note.categories.length) {
        let formattedDate = note.startDate.split("T")[0];
        if (tabIndexG === 1) {
          formattedDate = formattedDate.slice(0, 7); // 2023-02
        }
        let index = barData.findIndex((el) => el.dates === formattedDate);
        if (index === -1) {
          index = barData.length;
          barData[index] = {
            dates: formattedDate,
          };
        }
        if (NO_CATEGORY in barData[index]) {
          barData[index][NO_CATEGORY]++;
        } else {
          barData[index][NO_CATEGORY] = 1;

          if (!barDataKeys.includes(NO_CATEGORY)) {
            barDataKeys.push(NO_CATEGORY);
          }
        }
      }
    });
  }
  barData.sort((a, b) => a.dates.localeCompare(b.dates));
  const result = barData ? sumKeys(barData) : [];

  return (
    <Card title={"Progress summary"}>
      <div className={"relative"} style={{ height: "400px" }}>
        {result.length === 0 && <NoDataPlaceholder />}
        <MyResponsivePie data={result} />
      </div>
    </Card>
  );
};

export default ProgressSummary;

function sumKeys(
  array: {
    dates: string;
    [key: string]: number | string;
  }[],
): { label: string; id: string; value: number }[] {
  const sums: { [key: string]: number } = {};

  array.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      if (key !== "dates") {
        sums[key] = (sums[key] || 0) + (obj[key] as number);
      }
    });
  });

  const result = Object.keys(sums).map((key) => ({
    label: key,
    id: key,
    value: sums[key],
  }));
  return result;
}

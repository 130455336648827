import React, { useEffect } from "react";
import { SignupButton } from "../../components/buttons/signup-button";
import { LoginButton } from "../../components/buttons/login-button";
import { useAuth0 } from "@auth0/auth0-react";
import { redirect, useNavigate } from "react-router-dom";
import { RoutesEnum } from "../../enums/enums";
import Logo from "../../components/logo/logo";

export const LoginPage: React.FC = () => {
  const { isAuthenticated } = useAuth0();

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      return navigate(RoutesEnum.home);
    }
  }, [navigate, isAuthenticated]);

  return (
    <>
      <div className="flex min-h-full w-screen flex-col sm:supports-[min-height:100dvh]:min-h-[100dvh] md:grid md:grid-cols-2 lg:grid-cols-[60%_40%]">
        <div className="relative hidden flex-1 flex-col justify-center px-5 bg-gray-50 pt-8  md:flex md:px-6 md:py-[22px] lg:px-8">
          <nav className="left-0 top-8 flex w-full px-6 sm:absolute md:top-[22px] md:px-6 lg:px-8">
            <h1>
              <div className="flex cursor-default items-center text-[20px] font-bold leading-none lg:text-[22px]">
                <Logo size={30} />
                <div className="ml-2">ProgressViz</div>
              </div>
            </h1>
          </nav>
          <div className="flex flex-col text-[32px] leading-[1.5] ">
            <div className="-mt-4 flex w-full flex-col pr-5 md:pr-8 lg:pr-10">
              <p className="font-bold md:text-[40px]">Visualize Your Journey</p>
              <p className="md:text-[30px]">
                Empower your progress with ProgressViz
              </p>
            </div>
          </div>
        </div>
        <div className="relative flex grow flex-col items-center justify-between bg-white px-5 py-8 text-black dark:bg-black dark:text-white sm:rounded-t-[30px] md:rounded-none md:px-6">
          <nav className="flex w-full justify-start px-6 pb-8 md:hidden md:px-6 lg:px-8">
            <h1>
              <div className="flex cursor-default items-center text-[20px] font-bold leading-none lg:text-[22px]">
                <Logo size={30} />
                <div>ProgressViz</div>
              </div>
            </h1>
          </nav>
          <div className="relative flex w-full grow flex-col items-center justify-center">
            <h2 className="text-center text-[20px] font-bold leading-[1.2] md:text-[32px] md:leading-8">
              Get started
            </h2>
            <div className="mt-5 w-full max-w-[440px]">
              <div className="grid gap-x-3 gap-y-2 sm:grid-cols-2 sm:gap-y-0">
                <LoginButton />
                <SignupButton />
              </div>
            </div>
          </div>
          <div className="mt-10 flex flex-col justify-center ">
            <div className="flex justify-center text-gray-300 md:mb-3"></div>
            <div className="flex gap-3 py-3 text-xs text-token-text-tertiary">
              <a
                rel="noreferrer"
                className="cursor-pointer font-normal underline"
                target="_blank"
                href="https://progressviz.com/privacy-policy.html"
              >
                Privacy policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

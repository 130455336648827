import TextInput from "../../../../components/form/text-input";
import ErrorDataAlert from "../../../../components/error-data-alert/error-data-alert";
import React, { FC } from "react";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { IFormInput } from "./interface/interfaces";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { CategoryEntity } from "../../../../rtk/codegen/core-api";
import Card from "../../../../components/card/card";
import LoaderSpinner from "../../../../components/loader-spinner/loader-spinner";
import DButton from "../../../../components/d-button/d-button";

const CategoryForm: FC<{
  isLoading: boolean;
  loading: boolean;
  update?: boolean;
  onSubmit: any;
  errors: FieldErrors<IFormInput>;
  register: UseFormRegister<IFormInput>;
  disabled: boolean;
  error: FetchBaseQueryError | SerializedError;
  data: CategoryEntity;
}> = ({
  isLoading,
  loading,
  update,
  onSubmit,
  errors,
  register,
  disabled,
  error,
  data,
}) => {
  if (loading) return <LoaderSpinner />;

  return (
    <Card title={update ? "Update category" : "New category"}>
      <div>
        <form onSubmit={onSubmit}>
          <TextInput
            label={"Title"}
            name={"title"}
            errors={errors}
            register={register}
            options={{
              required: { value: true, message: "Field is required" },
              minLength: { value: 5, message: "Minimum 5 characters" },
            }}
          />
          <DButton
            onClick={undefined}
            isLoading={isLoading}
            className={"btn btn-primary w-full mt-2"}
            name={"Save"}
            disabled={disabled || isLoading}
          />
        </form>
      </div>

      <ErrorDataAlert data={data} error={error} />
    </Card>
  );
};

export default CategoryForm;

import React from "react";
import { DashboardPage } from "./dashboard-page";
import StartEndDateContextProvider from "./contexts/start-end-date-context";

export const DashboardPageWrapper = () => {
  return (
    <StartEndDateContextProvider>
      <DashboardPage />
    </StartEndDateContextProvider>
  );
};

export default DashboardPageWrapper;

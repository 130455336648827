import MyResponsiveCalendar from "../../../../components/my-responsive-calendar/my-responsive-calendar";
import React from "react";
import { useStartEndDateContext } from "../../contexts/start-end-date-context";
import { useNotesControllerFindAllQuery } from "../../../../rtk/codegen/core-api";
import Card from "../../../../components/card/card";
import NoDataPlaceholder from "../no-data-placeholder/no-data-placeholder";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERY_MAX_MOBILE_500 } from "../../../../constants/media-query";
import { NO_CATEGORY } from "../../../../constants/no-category";

const ProgressCalendar = () => {
  const { startDate, endDate } = useStartEndDateContext();
  const { data, isLoading, error } = useNotesControllerFindAllQuery({
    startDate,
    endDate,
    // pageSize: 115,
    // page: 1,
    // search: "gg",
  });

  let dailyData: {
    // @ts-ignore
    dates: string;
    [key: string]: number;
  }[] = [];
  if (data) {
    data?.data?.forEach((note) => {
      note.categories.forEach((category) => {
        let formattedDate = note.startDate.split("T")[0];
        let index = dailyData.findIndex((el) => el.dates === formattedDate);
        if (index === -1) {
          index = dailyData.length;
          // @ts-ignore
          dailyData[index] = {
            dates: formattedDate,
          };
        }
        if (category.title in dailyData[index]) {
          dailyData[index][category.title]++;
        } else {
          dailyData[index][category.title] = 1;
        }
      });
      if (!note.categories.length) {
        let formattedDate = note.startDate.split("T")[0];
        let index = dailyData.findIndex((el) => el.dates === formattedDate);
        if (index === -1) {
          index = dailyData.length;
          // @ts-ignore
          dailyData[index] = {
            dates: formattedDate,
          };
        }
        if (NO_CATEGORY in dailyData[index]) {
          dailyData[index][NO_CATEGORY]++;
        } else {
          dailyData[index][NO_CATEGORY] = 1;
        }
      }
    });
  }

  const dailyMappedData: {
    day: string;
    value: number;
  }[] = dailyData.map((el) => {
    const { dates, ...rest } = el;
    return {
      day: dates,
      value: Object.values(rest).reduce((acc, cur) => acc + cur, 0),
    };
  });

  const diff = Number(endDate.slice(0, 4)) - Number(startDate.slice(0, 4));

  const isMobile500 = useMediaQuery({
    query: `(max-width: ${MEDIA_QUERY_MAX_MOBILE_500}px)`,
  });

  const coef = isMobile500 ? 75 : 150;

  const height = diff < 0 ? 0 : (diff + 1) * coef;

  return (
    <>
      <div className="card bg-base-100 mt-5">
        <div className="card-body">
          <div className="prose">
            <h2 className="m-0">Progress calendar</h2>
          </div>
          <div
            className={"relative"}
            style={{
              height: height + "px",
              minHeight: isMobile500 ? "200px" : "400px",
            }}
          >
            {dailyMappedData.length === 0 && <NoDataPlaceholder />}
            {diff >= 0 && dailyMappedData.length !== 0 && (
              <MyResponsiveCalendar
                startDate={startDate}
                endDate={endDate}
                data={dailyMappedData}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgressCalendar;

import React, { FC } from "react";

const Logo: FC<{
  size: number;
}> = ({ size = 30 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={size}
      height={size}
      viewBox="0 0 128 128"
    >
      <g transform="matrix(0.6130952380952381,0,0,0.6130952380952381,22.860577560448917,10.669409090909085)">
        <svg
          viewBox="0 0 75 99"
          data-background-color="#ffffff"
          preserveAspectRatio="xMidYMid meet"
          height="168"
          width="128"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="tight-bounds"
            transform="matrix(1,0,0,1,1.8171057902263072,1.7595000000000027)"
          >
            <svg
              viewBox="0 0 71.3660799375 95.481"
              height="95.481"
              width="71.3660799375"
            >
              <g>
                <svg
                  viewBox="0 0 71.3660799375 95.481"
                  height="95.481"
                  width="71.3660799375"
                >
                  <g>
                    <svg
                      viewBox="0 0 71.3660799375 95.481"
                      height="95.481"
                      width="71.3660799375"
                    >
                      <g id="textblocktransform">
                        <svg
                          viewBox="0 0 71.3660799375 95.481"
                          height="95.481"
                          width="71.3660799375"
                          id="textblock"
                        >
                          <g>
                            <svg
                              viewBox="0 0 71.3660799375 95.481"
                              height="95.481"
                              width="71.3660799375"
                            >
                              <g>
                                <svg
                                  viewBox="0 0 71.3660799375 95.481"
                                  height="95.481"
                                  width="71.3660799375"
                                >
                                  <g>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      version="1.1"
                                      x="0"
                                      y="0"
                                      viewBox="6.062 0 35.877 48"
                                      enable-background="new 0 0 48 48"
                                      height="95.481"
                                      width="71.3660799375"
                                      className="icon-icon-0"
                                      data-fill-palette-color="accent"
                                      id="icon-0"
                                    >
                                      <g
                                        fill="#ed2647"
                                        data-fill-palette-color="accent"
                                      >
                                        <path
                                          d="M37.645 0H10.355A4.3 4.3 0 0 0 6.062 4.295V43.705A4.3 4.3 0 0 0 10.356 48H37.645A4.3 4.3 0 0 0 41.939 43.705V4.295A4.3 4.3 0 0 0 37.645 0M24.001 43.665A2.88 2.88 0 0 1 21.124 40.789 2.88 2.88 0 0 1 24.001 37.913 2.88 2.88 0 0 1 26.876 40.789 2.88 2.88 0 0 1 24.001 43.665M36.203 34.043H11.798V5.321H36.203z"
                                          fill="#ed2647"
                                          data-fill-palette-color="accent"
                                        ></path>
                                        <rect
                                          x="16.21"
                                          y="21.13"
                                          width="2.021"
                                          height="9.967"
                                          fill="#ed2647"
                                          data-fill-palette-color="accent"
                                        ></rect>
                                        <rect
                                          x="22.989"
                                          y="13.134"
                                          width="2.021"
                                          height="17.964"
                                          fill="#ed2647"
                                          data-fill-palette-color="accent"
                                        ></rect>
                                        <rect
                                          x="29.77"
                                          y="8.267"
                                          width="2.021"
                                          height="22.831"
                                          fill="#ed2647"
                                          data-fill-palette-color="accent"
                                        ></rect>
                                      </g>
                                    </svg>
                                  </g>
                                </svg>
                              </g>
                            </svg>
                          </g>
                        </svg>
                      </g>
                    </svg>
                  </g>
                </svg>
              </g>
              <defs></defs>
            </svg>
            <rect
              width="71.3660799375"
              height="95.481"
              fill="none"
              stroke="none"
              visibility="hidden"
            ></rect>
          </g>
        </svg>
      </g>
    </svg>
  );
};

export default Logo;

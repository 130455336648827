import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { PageLoader } from "./components/page-loader/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard/authentication-guard";
import { Route, Routes } from "react-router-dom";
import { CallbackPage } from "./pages/callback-page/callback-page";
import { LoginPage } from "./pages/login-page/login-page";
import { NotFoundPage } from "./pages/not-found-page/not-found-page";
import { NotesPostPage } from "./pages/notes-post-page/notes-post-page";
import { sec } from "./rtk/security";
import { GetNotesPage } from "./pages/get-notes-page/get-notes-page";
import { NotesUpdatePage } from "./pages/notes-update-page/notes-update-page";
import { GetCategoriesPage } from "./pages/get-categories-page/get-categories-page";
import { CategoriesUpdatePage } from "./pages/categories-update-page/categories-update-page";
import { RoutesEnum } from "./enums/enums";
import { getNotesRouteById } from "./utils/get-notes-route-by-id";
import { getCategoriesRouteById } from "./utils/get-categories-route-by-id";
import DashboardPageWrapper from "./pages/dashboard-page/dashboard-page-wrapper";
import GetNotesPageWrapper from "./pages/get-notes-page/get-notes-page-wrapper";

export const App: React.FC = () => {
  const { isLoading, getAccessTokenSilently } = useAuth0();

  // https://github.com/reduxjs/redux-toolkit/issues/1331#issuecomment-1080901326
  // set getAccessTokenSilently in global module to reuse it outside a React component
  sec.setAccessTokenSilently(getAccessTokenSilently);

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <Routes>
      <Route path={RoutesEnum.login} element={<LoginPage />} />

      <Route path={RoutesEnum.callback} element={<CallbackPage />} />

      <Route
        path={RoutesEnum.home}
        element={<AuthenticationGuard component={DashboardPageWrapper} />}
      />
      <Route
        path={RoutesEnum.notes}
        element={<AuthenticationGuard component={GetNotesPageWrapper} />}
      />
      <Route
        path={RoutesEnum.notesPost}
        element={<AuthenticationGuard component={NotesPostPage} />}
      />
      <Route
        path={getNotesRouteById()}
        element={<AuthenticationGuard component={NotesUpdatePage} />}
      />
      <Route
        path={RoutesEnum.categories}
        element={<AuthenticationGuard component={GetCategoriesPage} />}
      />
      <Route
        path={getCategoriesRouteById()}
        element={<AuthenticationGuard component={CategoriesUpdatePage} />}
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

import TextInput from "../../../components/form/text-input";
import Select from "../../../components/form/select";
import DatePicker from "../../../components/form/date-picker";
import ErrorDataAlert from "../../../components/error-data-alert/error-data-alert";
import Card from "../../../components/card/card";
import React, { FC } from "react";
import {
  CategoriesControllerFindAllApiResponse,
  NoteEntity,
} from "../../../rtk/codegen/core-api";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { Control, FieldErrors, UseFormRegister } from "react-hook-form";
import { IFormInput } from "../interfaces/interfaces";
import LoaderSpinner from "../../../components/loader-spinner/loader-spinner";
import DButton from "../../../components/d-button/d-button";

const NoteForm: FC<{
  isLoading: boolean;
  update?: boolean;
  onSubmit: any;
  errors: FieldErrors<IFormInput>;
  register: UseFormRegister<IFormInput>;
  dataCategories: CategoriesControllerFindAllApiResponse;
  control: Control<IFormInput, any>;
  data: NoteEntity;
  error: FetchBaseQueryError | SerializedError;
  disabled: boolean;
  loading: boolean;
}> = ({
  isLoading,
  update,
  onSubmit,
  errors,
  register,
  dataCategories,
  control,
  data,
  error,
  disabled,
  loading,
}) => {
  if (loading) return <LoaderSpinner />;

  return (
    <Card title={update ? "Update note" : "New note"}>
      <form onSubmit={onSubmit}>
        <TextInput
          label={"Title"}
          name={"title"}
          errors={errors}
          register={register}
          options={{
            required: { value: true, message: "Field is required" },
            minLength: { value: 5, message: "Minimum 5 characters" },
          }}
        />

        <TextInput
          label={"Body"}
          name={"body"}
          errors={errors}
          register={register}
          options={{
            required: { value: true, message: "Field is required" },
          }}
        />

        <Select
          label={"Categories"}
          name={"categories"}
          register={register}
          selectOptions={
            dataCategories?.map((el) => ({
              title: el.title,
              value: el.id.toString(),
            })) || []
          }
        />

        <DatePicker
          errors={errors}
          options={{
            required: { value: true, message: "Field is required" },
          }}
          label={"Date"}
          name={"startDate"}
          control={control}
        />

        <DButton
          className={"btn btn-primary w-full mt-3"}
          disabled={isLoading}
          isLoading={isLoading}
          name={"Save"}
          onClick={undefined}
        />
      </form>

      <ErrorDataAlert data={data} error={error} />
    </Card>
  );
};

export default NoteForm;

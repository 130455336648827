import React, { useEffect } from "react";
import {
  useCategoriesControllerFindOneQuery,
  useCategoriesControllerUpdateMutation,
} from "../../rtk/codegen/core-api";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { UpdateCategoryParams } from "../../navigation/types";
import CategoryForm from "../get-categories-page/components/category-form/category-form";
import { IFormInput } from "../get-categories-page/components/category-form/interface/interfaces";
import { Layout } from "../../components/layout/layout";

export const CategoriesUpdatePage: React.FC = () => {
  const [categoriesControllerCreate, { isLoading, data, error }] =
    useCategoriesControllerUpdateMutation();

  const { id } = useParams<UpdateCategoryParams>();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<IFormInput>({
    defaultValues: {
      title: "",
    },
  });

  const { data: dataFind, isLoading: isLoadingCategories } =
    useCategoriesControllerFindOneQuery({
      id: Number(id),
    });
  const onSubmit: SubmitHandler<IFormInput> = async (values) => {
    try {
      await categoriesControllerCreate({
        id: Number(id),
        updateCategoryDto: {
          title: values.title,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (dataFind) {
      reset({
        title: dataFind?.title,
      });
    }
  }, [dataFind, reset]);

  return (
    <Layout small>
      <CategoryForm
        isLoading={isLoading}
        loading={isLoadingCategories}
        update
        error={error}
        data={data}
        onSubmit={handleSubmit(onSubmit)}
        disabled={!isValid}
        register={register}
        errors={errors}
      />
    </Layout>
  );
};

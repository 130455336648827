import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { usePaginationPageContext } from "./pagination-page-context";

export const SearchContext = createContext<{
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}>({
  search: null,
  setSearch: () => undefined,
});

export const useSearchContext = () => useContext(SearchContext);

const SearchContextProvider: FC<{ children: React.ReactNode }> = (props) => {
  const [search, setSearch] = useState<string>("");

  const { setPaginationPage } = usePaginationPageContext();
  useEffect(() => {
    setPaginationPage(1);
  }, [search]);

  const contextValue = useMemo(() => {
    return {
      search,
      setSearch,
    };
  }, [search, setSearch]);

  return (
    <SearchContext.Provider value={contextValue}>
      {props.children}
    </SearchContext.Provider>
  );
};

export default SearchContextProvider;

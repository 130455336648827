import React, { createContext, FC, useContext, useMemo, useState } from "react";

export const PaginationPageContext = createContext<{
  paginationPage: number;
  setPaginationPage: React.Dispatch<React.SetStateAction<number>>;
}>({
  paginationPage: null,
  setPaginationPage: () => undefined,
});

export const usePaginationPageContext = () => useContext(PaginationPageContext);

const PaginationPageContextProvider: FC<{ children: React.ReactNode }> = (
  props,
) => {
  const [paginationPage, setPaginationPage] = useState<number>(1);

  const contextValue = useMemo(() => {
    return {
      paginationPage,
      setPaginationPage,
    };
  }, [paginationPage, setPaginationPage]);

  return (
    <PaginationPageContext.Provider value={contextValue}>
      {props.children}
    </PaginationPageContext.Provider>
  );
};

export default PaginationPageContextProvider;

import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { RoutesEnum } from "../../enums/enums";

export const SignupButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: RoutesEnum.home,
      },
      authorizationParams: {
        prompt: "login",
        screen_hint: "signup",
      },
    });
  };

  return (
    <button
      onClick={handleSignUp}
      className="relative flex h-12 items-center justify-center rounded-md text-center text-base font-semibold bg-black text-[#fff] hover:bg-gray-700"
    >
      <div className="relative -top-[1px]">Sign up</div>
    </button>
  );
};

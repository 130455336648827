import React, { createContext, FC, useContext, useMemo, useState } from "react";
import dayjs from "dayjs";
import { dateFormat } from "../../../constants/date-format";

export const StartEndDateContext = createContext<{
  startDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  endDate: string;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
}>({
  startDate: null,
  setStartDate: () => undefined,
  endDate: null,
  setEndDate: () => undefined,
});

export const useStartEndDateContext = () => useContext(StartEndDateContext);

const StartEndDateContextProvider: FC<{ children: React.ReactNode }> = (
  props,
) => {
  const [startDate, setStartDate] = useState<string>(
    dayjs().subtract(6, "month").format(dateFormat["YYYY-MM-DD"]),
  );
  const [endDate, setEndDate] = useState<string>(
    dayjs().format(dateFormat["YYYY-MM-DD"]),
  );

  const contextValue = useMemo(() => {
    return {
      startDate,
      setStartDate,
      endDate,
      setEndDate,
    };
  }, [startDate, setStartDate, endDate, setEndDate]);

  return (
    <StartEndDateContext.Provider value={contextValue}>
      {props.children}
    </StartEndDateContext.Provider>
  );
};

export default StartEndDateContextProvider;

import React from "react";

export const LoaderSpinner: React.FC = () => {
  return (
    <div className={"text-center py-2"}>
      <span className="loading loading-dots"></span>
    </div>
  );
};

export default LoaderSpinner;

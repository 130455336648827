import React from "react";
import { Footer } from "../footer/footer";
import NavigationBar from "../navigation/navigation-bar";

interface Props {
  children: JSX.Element;
  small?: boolean;
}

export const Layout: React.FC<Props> = ({ children, small }) => {
  // https://www.reddit.com/r/Frontend/comments/11rh7jj/how_to_make_footer_always_at_bottom_of_scroll/
  return (
    <main className="min-h-screen flex flex-col justify-between bg-gray-100">
      <section className="flex-grow">
        <NavigationBar />
        <div
          className={
            small
              ? "px-2 md:px-0 lg:w-2/4 mx-auto"
              : "px-2 md:px-0 md:w-3/4 mx-auto"
          }
        >
          {children}
        </div>
      </section>
      <Footer />
    </main>
  );
};

import { ActivityPeriodEnum } from "../enums/enums";

export const activityCalendarTranslationMapper: {
  [key in ActivityPeriodEnum]: string;
} = {
  [ActivityPeriodEnum.month12]: "Last 12 months",
  [ActivityPeriodEnum.month6]: "Last 6 months",
  [ActivityPeriodEnum.month3]: "Last 3 months",
  [ActivityPeriodEnum.days30]: "Last 30 days",
  [ActivityPeriodEnum.thisMonth]: "This month",
  [ActivityPeriodEnum.days7]: "7 days",
};

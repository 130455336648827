import Card from "../../../../components/card/card";
import React from "react";
import { useNotesControllerRemoveAllMutation } from "../../../../rtk/codegen/core-api";
import DButton from "../../../../components/d-button/d-button";

const modalId = "modalConfirmDeletion";

const DeleteAllNotes = () => {
  const [notesControllerRemoveAll, { isLoading: isLoadingRemove }] =
    useNotesControllerRemoveAllMutation();

  const getModal = (id: string) =>
    document.getElementById(id) as HTMLDialogElement;

  return (
    <Card title={"Danger zone"}>
      <button
        className="btn btn-error w-full"
        onClick={() => {
          getModal(modalId).showModal();
        }}
      >
        Delete all notes
      </button>
      <dialog id={modalId} className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Confirm deletion</h3>
          <p className="py-4">Do you want to delete all your notes?</p>
          <div className="modal-action">
            <form method="dialog" className="flex gap-2">
              <button className="btn">Cancel</button>
              <DButton
                className={"btn btn-error"}
                disabled={isLoadingRemove}
                isLoading={isLoadingRemove}
                name={"Delete"}
                onClick={async (e: any) => {
                  e.preventDefault();
                  await notesControllerRemoveAll();
                  getModal(modalId).close();
                }}
              />
            </form>
          </div>
        </div>
      </dialog>
    </Card>
  );
};

export default DeleteAllNotes;

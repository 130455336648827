import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import { apiSlice } from "./features/api.slice";
import { emptySplitApi } from "./codegen/create-api";
import localPreferencesReducer from "./features/local-preferences.slice";
import discoveryPickerReducer from "./features/discovery-picker.slice";
import { rtkQueryErrorLogger } from "./middlewares/rtk-query-error-logger";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
    localPreferences: localPreferencesReducer,
    discoveryPicker: discoveryPickerReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
      serializableCheck: {
        warnAfter: 128,
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER], // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
      },
    })
      .concat(apiSlice.middleware)
      .concat(emptySplitApi.middleware)
      .concat(rtkQueryErrorLogger),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);

// todo: remove
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export interface DiscoveryPickerSlice {
  pickerNumber: number;
}

const initialState: DiscoveryPickerSlice = {
  pickerNumber: 0,
};

export const discoveryPickerSlice = createSlice({
  name: "discoveryPicker",
  initialState,
  reducers: {
    setPickerNumber: (state, action: PayloadAction<number>) => {
      state.pickerNumber = action.payload;
    },
  },
});

export const { setPickerNumber } = discoveryPickerSlice.actions;

export default discoveryPickerSlice.reducer;

import { useState } from "react";

const useLoadingStates = () => {
  const [loadingStates, setLoadingStates] = useState<{
    [id: number]: boolean;
  }>({});

  const handleRemove = async (id: number, cb: any) => {
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [id]: true,
    }));

    try {
      await cb({ id });
    } catch (error) {
    } finally {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [id]: false,
      }));
    }
  };

  return {
    loadingStates,
    handleRemove,
  };
};

export default useLoadingStates;

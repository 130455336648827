import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./app";
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import "./styles/styles.css";
import { Provider } from "react-redux";
import { persistor, store } from "./rtk/store";
import { PersistGate } from "redux-persist/integration/react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

const container = document.getElementById("root") as HTMLElement;

const root = createRoot(container);

dayjs.extend(customParseFormat);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Auth0ProviderWithNavigate>
            <App />
          </Auth0ProviderWithNavigate>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);

import React, { FC } from "react";

const ErrorDataAlert: FC<{
  error: any;
  data: any;
}> = ({ data, error }) => {
  return (
    <>
      {error || data ? (
        <div role="alert" className="alert shadow-lg mt-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="stroke-info shrink-0 w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <div>
            <h3 className="font-bold">
              {error ? "Error" : data ? "Success" : ""}
            </h3>
            <div className="text-xs hidden">
              {error ? JSON.stringify(error) : data ? JSON.stringify(data) : ""}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ErrorDataAlert;

import Card from "../../../../components/card/card";
import React, { useEffect, useState } from "react";
import Search from "../../../../components/search/search";
import { useSearchContext } from "../../contexts/search-context";
import { usePaginationPageContext } from "../../contexts/pagination-page-context";

const FilterNotes = () => {
  const { search, setSearch } = useSearchContext();
  const [value, setValue] = useState(search);

  // reset from clearing badge filter
  useEffect(() => {
    setValue(search);
  }, [search]);

  return (
    <Card title={"Filter notes"}>
      <Search value={value} setValue={setValue} />

      <button
        className="mt-2 btn btn-primary w-full"
        onClick={() => {
          setSearch(value);
        }}
      >
        Search
      </button>
    </Card>
  );
};

export default FilterNotes;

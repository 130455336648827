import React from "react";
import { Layout } from "../../components/layout/layout";
import Card from "../../components/card/card";

export const NotFoundPage: React.FC = () => {
  return (
    <Layout small>
      <Card title={"Not found"}>{null}</Card>
    </Layout>
  );
};

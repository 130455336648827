import React, { FC } from "react";

const Card: FC<{
  children: React.ReactNode;
  title: string;
}> = (props) => {
  return (
    <div className="card bg-base-100 mt-5">
      <div className="card-body">
        <div className="prose">
          <h2 className="mb-0">{props.title}</h2>
        </div>
        <div>{props.children}</div>
      </div>
    </div>
  );
};

export default Card;

import React from "react";
import { GetNotesPage } from "./get-notes-page";
import PaginationPageContextProvider from "./contexts/pagination-page-context";
import SearchContextProvider from "./contexts/search-context";
import CategoriesContextProvider from "./contexts/categories-context";

export const GetNotesPageWrapper = () => {
  return (
    <PaginationPageContextProvider>
      <SearchContextProvider>
        <CategoriesContextProvider>
          <GetNotesPage />
        </CategoriesContextProvider>
      </SearchContextProvider>
    </PaginationPageContextProvider>
  );
};

export default GetNotesPageWrapper;

// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/bar
import { ResponsiveBar } from "@nivo/bar";
import { useState } from "react";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const maxLabelsToShow = 5;
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERY_MAX_MOBILE } from "../../constants/media-query";

const MyResponsiveBar = ({ data, keys }: { data: any; keys: string[] }) => {
  const [tabIndex, setTabIndex] = useState(1);

  const gr = Math.floor(data.length / maxLabelsToShow);
  const valuesToShow = data.map((v: any, i: number) =>
    data.length < maxLabelsToShow || i % gr === 0 ? v : "",
  );

  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${MEDIA_QUERY_MAX_MOBILE}px)`,
  });

  return (
    <>
      <div className="flex justify-end">
        <div role="tablist" className="tabs tabs-bordered">
          <a
            role="tab"
            className={`tab ${tabIndex === 0 ? "tab-active" : ""}`}
            onClick={() => {
              setTabIndex(0);
            }}
          >
            Grouped
          </a>
          <a
            role="tab"
            className={`tab ${tabIndex === 1 ? "tab-active" : ""}`}
            onClick={() => {
              setTabIndex(1);
            }}
          >
            Stacked
          </a>
        </div>
      </div>
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy="dates"
        margin={{
          top: 50,
          right: isTabletOrMobile ? 90 : 130,
          bottom: 50,
          left: 60,
        }}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: "nivo" }}
        groupMode={tabIndex === 0 ? "grouped" : "stacked"}
        // defs={[
        //   {
        //     id: "dots",
        //     type: "patternDots",
        //     background: "inherit",
        //     color: "#4138bc",
        //     size: 4,
        //     padding: 1,
        //     stagger: true,
        //   },
        //   {
        //     id: "lines",
        //     type: "patternLines",
        //     background: "inherit",
        //     color: "#eed312",
        //     rotation: -45,
        //     lineWidth: 6,
        //     spacing: 10,
        //   },
        // ]}
        // fill={[
        //   {
        //     match: {
        //       id: "fries",
        //     },
        //     id: "dots",
        //   },
        //   {
        //     match: {
        //       id: "sandwich",
        //     },
        //     id: "lines",
        //   },
        // ]}
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: isTabletOrMobile ? 2 : 5,
          tickPadding: 5,
          tickRotation: isTabletOrMobile ? 35 : 0,
          legend: "Date",
          legendPosition: isTabletOrMobile ? "start" : "middle",
          legendOffset: isTabletOrMobile ? 25 : 32,
          truncateTickAt: 0,
          format: (v) =>
            valuesToShow.find((vts: any) => {
              return vts.dates === v;
            })
              ? v
              : "",
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Number of notes",
          legendPosition: "middle",
          legendOffset: -40,
          truncateTickAt: 0,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: isTabletOrMobile ? 110 : 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
        // ariaLabel="Nivo bar chart demo"
        // barAriaLabel={(e) =>
        //   e.id + ": " + e.formattedValue + " in country: " + e.indexValue
        // }
      />
    </>
  );
};

export default MyResponsiveBar;

import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Layout } from "../../components/layout/layout";
import Card from "../../components/card/card";

export const CallbackPage: React.FC = () => {
  const { error } = useAuth0();

  if (error) {
    return (
      <Layout>
        <Card title={"Error"}>
          <p id="page-description">
            <span>{error.message}</span>
          </p>
        </Card>
      </Layout>
    );
  }

  return (
    <Layout>
      <div>No error (callback)</div>
    </Layout>
  );
};

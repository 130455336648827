import { Link } from "react-router-dom";
import { getNotesRouteById } from "../../../../utils/get-notes-route-by-id";
import React from "react";
import { useNotesControllerFindAllQuery } from "../../../../rtk/codegen/core-api";
import Card from "../../../../components/card/card";
import { getNoteDate } from "../../../../utils/get-note-date";

const RecentNotes = () => {
  const { data, isLoading, error } = useNotesControllerFindAllQuery({
    pageSize: 5,
    page: 1,
  });

  return (
    <Card title={"Recent notes"}>
      <div className="overflow-x-auto">
        <table className="table table-xs">
          <thead>
            <tr>
              <th>Title</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {data?.data?.map((el) => (
              <tr key={el.id}>
                <td>
                  <Link
                    className="link link-primary"
                    to={getNotesRouteById(el.id)}
                  >
                    {el.title}
                  </Link>
                </td>
                <td>{getNoteDate(el)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default RecentNotes;

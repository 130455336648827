import React from "react";

export const PageLoader: React.FC = () => {
  return (
    <main className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="text-center">
        <div>Loading...</div>
        <div className="loading loading-dots"></div>
      </div>
    </main>
  );
};

import { Link } from "react-router-dom";
import { getNotesRouteById } from "../../../../utils/get-notes-route-by-id";
import { getCategoriesRouteById } from "../../../../utils/get-categories-route-by-id";
import React, { useState } from "react";
import {
  useCategoriesControllerFindAllQuery,
  useNotesControllerFindAllQuery,
  useNotesControllerRemoveMutation,
} from "../../../../rtk/codegen/core-api";
import Card from "../../../../components/card/card";
import Pagination from "../../../../components/pagination/pagination";
import { pageSize } from "../../constants/constants";
import { usePaginationPageContext } from "../../contexts/pagination-page-context";
import { useSearchContext } from "../../contexts/search-context";
import Badge from "../../../../components/badge/badge";
import { useCategoriesContext } from "../../contexts/categories-context";
import { getNoteDate } from "../../../../utils/get-note-date";
import DButton from "../../../../components/d-button/d-button";
import useLoadingStates from "./use-loading-states";
import LoaderSpinner from "../../../../components/loader-spinner/loader-spinner";

const TableNotes = () => {
  const { paginationPage, setPaginationPage } = usePaginationPageContext();
  const { search, setSearch } = useSearchContext();
  const { categories } = useCategoriesContext();

  const { data, isLoading, error } = useNotesControllerFindAllQuery({
    page: paginationPage,
    pageSize,
    search,
    categories: categories.length > 0 ? categories : undefined,
  });

  const { data: dataCategories } = useCategoriesControllerFindAllQuery();

  const [notesControllerRemove, { isLoading: isLoadingRemove }] =
    useNotesControllerRemoveMutation();

  const { loadingStates, handleRemove } = useLoadingStates();

  const { setValue } = useCategoriesContext();

  if (isLoading) return <LoaderSpinner />;
  if (!data) return null;

  return (
    <Card title={"Notes"}>
      <div className={"mb-2"}>
        Total: <b>{data?.meta?.total}</b>
      </div>
      <div className="flex flex-row flex-wrap gap-2 mb-2">
        {!!search && (
          <Badge
            onClick={() => {
              setSearch("");
            }}
            title={`Search: ${search}`}
          />
        )}
        {categories?.map((el) => (
          <Badge
            key={el}
            onClick={() => {
              setValue(
                "categories",
                categories
                  .filter((cat) => cat !== el)
                  .map((cat) => cat.toString()),
              );
            }}
            title={dataCategories?.find((cat) => cat.id === el)?.title}
          />
        ))}
        {(!!search || categories?.length > 0) && (
          <button
            className="btn btn-sm"
            onClick={() => {
              setValue("categories", []);
              setSearch("");
            }}
          >
            Clear all
          </button>
        )}
      </div>

      <div className="overflow-x-auto">
        <table className="table table-xs">
          <thead>
            <tr>
              {/*<th>#</th>*/}
              <th>Title</th>
              <th>Description</th>
              <th>Categories</th>
              <th>Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data?.data?.map((el) => (
              <tr key={el.id}>
                {/*<th>{el.id}</th>*/}
                <td>
                  <Link
                    className="link link-primary"
                    to={getNotesRouteById(el.id)}
                  >
                    {el.title}
                  </Link>
                </td>
                <td>{el.body}</td>
                <td>
                  {el.categories.map((category) => (
                    <Link
                      key={category.id}
                      className="kbd kbd-xs last:mr-0 mr-2 hover:border-gray-500"
                      to={getCategoriesRouteById(category.id)}
                    >
                      {category.title}
                    </Link>
                  ))}
                </td>
                <td>{getNoteDate(el)}</td>
                <td>
                  <DButton
                    hideSpinner
                    className={"w-full btn btn-error btn-xs"}
                    disabled={loadingStates[el.id]}
                    isLoading={loadingStates[el.id]}
                    name={"Delete"}
                    onClick={() =>
                      handleRemove(Number(el.id), notesControllerRemove)
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        className="pagination-bar"
        currentPage={paginationPage}
        totalCount={data?.meta?.total}
        pageSize={pageSize}
        onPageChange={(page: number) => setPaginationPage(page)}
      />
    </Card>
  );
};

export default TableNotes;

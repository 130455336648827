import React from "react";
import { Layout } from "../../components/layout/layout";
import { useNotesControllerFindAllQuery } from "../../rtk/codegen/core-api";
import TableNotes from "./components/table-notes/table-notes";
import LoaderSpinner from "../../components/loader-spinner/loader-spinner";
import DeleteAllNotes from "./components/delete-all-notes/delete-all-notes";
import FiltersNotes from "./components/filters-notes/filters-notes";
import { pageSize } from "./constants/constants";
import { usePaginationPageContext } from "./contexts/pagination-page-context";
import FiltersCategoriesNotes from "./components/filters-categories-notes/filters-categories-notes";

export const GetNotesPage: React.FC = () => {
  const { paginationPage } = usePaginationPageContext();
  const { data, isLoading, error } = useNotesControllerFindAllQuery({
    page: paginationPage,
    pageSize,
  });

  return (
    <Layout>
      <>
        {isLoading && <LoaderSpinner />}
        {data && (
          <div className={"xl:flex xl:flex-row xl:space-x-6"}>
            <div className={"xl:w-3/4"}>
              <TableNotes />
            </div>
            <div className={"xl:w-1/4 xl:sticky xl:top-0 h-full"}>
              <FiltersNotes />
              <FiltersCategoriesNotes />
              <DeleteAllNotes />
            </div>
          </div>
        )}
      </>
    </Layout>
  );
};

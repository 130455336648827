import { ActivityPeriodEnum } from "../enums/enums";
import { dateFormat } from "../../../../../constants/date-format";
import dayjs from "dayjs";

export const getActivityPeriodByEnum = (
  activityPeriod: ActivityPeriodEnum,
): {
  startDate: string;
  endDate: string;
} => {
  const format = dateFormat["YYYY-MM-DD"];
  let startDate = "";
  let endDate = dayjs().format(format); // defaultEndDate

  switch (activityPeriod) {
    case ActivityPeriodEnum.month12: {
      startDate = dayjs().subtract(12, "month").format(format);
      break;
    }
    case ActivityPeriodEnum.month6: {
      startDate = dayjs().subtract(6, "month").format(format);
      break;
    }
    case ActivityPeriodEnum.month3: {
      startDate = dayjs().subtract(3, "month").format(format);
      break;
    }
    case ActivityPeriodEnum.days30: {
      startDate = dayjs().subtract(30, "days").format(format);
      break;
    }
    case ActivityPeriodEnum.thisMonth: {
      startDate = dayjs().startOf("months").format(format);
      endDate = dayjs().endOf("months").format(format);
      break;
    }
    case ActivityPeriodEnum.days7: {
      startDate = dayjs().subtract(7, "days").format(format);
      break;
    }
    default:
      return activityPeriod satisfies never;
  }

  return {
    startDate: startDate,
    endDate: endDate,
  };
};

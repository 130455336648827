import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { RoutesEnum } from "../../enums/enums";

export const LoginButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: RoutesEnum.home,
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  return (
    <button
      onClick={handleLogin}
      className="relative flex h-12 items-center justify-center rounded-md text-center text-base font-semibold bg-primary text-[#fff] hover:bg-red-800"
      data-testid="login-button"
    >
      <div className="relative -top-[1px]">Log in</div>
    </button>
  );
};

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ApiNotesInterface } from "../interfaces/api-notes.interface";
import { sec } from "../security";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_SERVER_URL}/`,
    prepareHeaders: async (headers) => {
      headers.set("Accept", "application/json");
      if (!headers.has("Authorization")) {
        const access_token = await sec.getAccessTokenSilently()();
        if (access_token) {
          headers.set("Authorization", `Bearer ${access_token}`);
        }
      }
      return headers;
    },
    timeout: 15000,
  }),
  endpoints(builder) {
    return {
      fetchNotes: builder.query<
        ApiNotesInterface[],
        {
          some?: number;
        }
      >({
        query({ some }) {
          return {
            url: "notes",
            params: {
              someParam: some,
            },
          };
        },
      }),
    };
  },
});

export const { useFetchNotesQuery } = apiSlice;

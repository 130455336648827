import React, { FC } from "react";
import { RegisterOptions } from "react-hook-form/dist/types/validator";

const TextInput: FC<{
  name: string;
  label: string;
  register: any; // todo
  errors: any; // todo
  options: RegisterOptions;
  small?: boolean;
}> = ({ name, register, label, errors, options, small }) => {
  return (
    <label className="form-control w-full block">
      <div className={!!label ? "label" : ""}>
        {!!label && <span className="label-text">{label}</span>}
        <span className="label-text-alt text-error">
          {errors[name] ? errors[name].message : ""}
        </span>
      </div>

      <input
        type="text"
        {...register(name, options)}
        placeholder={label}
        className={`input input-bordered w-full block ${
          errors[name] ? "input-error" : ""
        } ${small ? "input-xs" : "mb-2"}`}
      />
      {/*<div className="label">*/}
      {/*  <span className="label-text-alt">*/}
      {/*    {errors[name] ? errors[name].message : ""}*/}
      {/*  </span>*/}
      {/*  <span className="label-text-alt"></span>*/}
      {/*</div>*/}
    </label>
  );
};

export default TextInput;

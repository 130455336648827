import React from "react";
import { Layout } from "../../components/layout/layout";
import { useNotesControllerFindAllQuery } from "../../rtk/codegen/core-api";
import ActivityCalendar from "./components/activity-calendar/activity-calendar";
import { useStartEndDateContext } from "./contexts/start-end-date-context";
import RecentNotes from "./components/recent-notes/recent-notes";
import ProgressTimeline from "./components/progress-timeline/progress-timeline";
import ProgressCalendar from "./components/progress-calendar/progress-calendar";
import ProgressSummary from "./components/progress-summary/progress-summary";
import LoaderSpinner from "../../components/loader-spinner/loader-spinner";
import ImportDataDemo from "./components/import-data/import-data-demo";

export const DashboardPage: React.FC = () => {
  const { startDate, endDate } = useStartEndDateContext();
  const { data, isLoading } = useNotesControllerFindAllQuery({
    startDate,
    endDate,
    // pageSize: 115,
    // page: 1,
    // search: "gg",
  });

  return (
    <Layout>
      <>
        {isLoading && <LoaderSpinner />}
        {data && (
          <div className={"xl:flex xl:flex-row xl:space-x-6"}>
            <div className={"xl:w-2/3"}>
              <ProgressTimeline />
              <ProgressCalendar />
              <ProgressSummary />
            </div>
            <div className={"xl:w-1/3 xl:sticky xl:top-0 h-full"}>
              <ActivityCalendar />
              <RecentNotes />
              {/*<ImportData />*/}
              <ImportDataDemo />
            </div>
          </div>
        )}
      </>
    </Layout>
  );
};

import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { SignupButton } from "../buttons/signup-button";
import { LoginButton } from "../buttons/login-button";
import { LogoutButton } from "../buttons/logout-button";
import { RouteNamesEnum, RoutesEnum } from "../../enums/enums";
import Logo from "../logo/logo";

const NavigationBar = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <div className="border-b border-b-white">
      <div className="navbar bg-base-100 border-b border-b-gray-200">
        <div className="navbar-start">
          <div className="dropdown">
            <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </div>
            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52"
            >
              <NavMenu isAuthenticated={isAuthenticated} />
            </ul>
          </div>
          <NavLink to={RoutesEnum.home}>
            <a className="btn btn-ghost sm:text-xl text-lg">
              <span className={"hidden sm:block"}>
                <Logo size={25} />
              </span>
              ProgressViz
            </a>
          </NavLink>
        </div>
        <div className="navbar-center hidden lg:flex">
          <ul className="menu menu-horizontal px-1">
            <NavMenu isAuthenticated={isAuthenticated} />
          </ul>
        </div>
        <div className="navbar-end gap-2">
          {!isAuthenticated ? ( // TODO: probably delete, we wont have navigation bar for anonymous. Callback errored have navbar
            <>
              {/*<SignupButton />*/}
              {/*<LoginButton />*/}
            </>
          ) : (
            <LogoutButton />
          )}
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;

const NavMenu: FC<{
  isAuthenticated: boolean;
}> = ({ isAuthenticated }) => {
  return (
    <>
      {/*<NavBarTab path="/profile" label="Profile" />*/}
      {isAuthenticated && (
        <>
          <NavTab path={RoutesEnum.notes} label={RouteNamesEnum.notes} />
          <NavTab
            path={RoutesEnum.categories}
            label={RouteNamesEnum.categories}
          />
          <NavTab
            path={RoutesEnum.notesPost}
            label={RouteNamesEnum.notesPost}
          />
        </>
      )}
      {/*mobile*/}
      {/*<li>*/}
      {/*  <a>Parent</a>*/}
      {/*  <ul className="p-2">*/}
      {/*    <li>*/}
      {/*      <a>Submenu 1</a>*/}
      {/*    </li>*/}
      {/*    <li>*/}
      {/*      <a>Submenu 2</a>*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</li>*/}

      {/*  desktop*/}
      {/*<li>*/}
      {/*  <details>*/}
      {/*    <summary>Parent</summary>*/}
      {/*    <ul className="p-2">*/}
      {/*      <li>*/}
      {/*        <a>Submenu 1</a>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        <a>Submenu 2</a>*/}
      {/*      </li>*/}
      {/*    </ul>*/}
      {/*  </details>*/}
      {/*</li>*/}
    </>
  );
};

const NavTab: FC<{
  path: string;
  label: string;
}> = ({ path, label }) => {
  return (
    <NavLink
      to={path}
      end
      className={({ isActive }) => `${isActive ? "text-primary" : ""}`}
    >
      <li>
        <a>{label}</a>
      </li>
    </NavLink>
  );
};

import React from "react";
import { useImportControllerImportDataMutation } from "../../../../rtk/codegen/core-api";
import ErrorDataAlert from "../../../../components/error-data-alert/error-data-alert";
import Card from "../../../../components/card/card";
import DButton from "../../../../components/d-button/d-button";

const ImportDataDemo = () => {
  const [notesControllerImportDataMutation, { isLoading, error, data }] =
    useImportControllerImportDataMutation();

  const handleUploadClick = async () => {
    await notesControllerImportDataMutation({
      createImportDto: {
        importedData: "1",
      },
    });
  };

  return (
    <Card title={"Import demo data"}>
      <label className=" w-full max-w-xs">
        <span className="label-text">
          Get started quickly by importing demo data. Discover the possibilities
          of our app with pre-filled notes covering various topics. Click
          'Import' to explore!
        </span>
      </label>
      <DButton
        isLoading={isLoading}
        className={"btn btn-primary w-full mt-3"}
        name={"Import"}
        disabled={isLoading}
        onClick={handleUploadClick}
      />
      <ErrorDataAlert data={data} error={error} />
    </Card>
  );
};

export default ImportDataDemo;

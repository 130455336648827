import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { usePaginationPageContext } from "./pagination-page-context";

export const CategoriesContext = createContext<{
  categories: number[];
  register: any;
  setValue: any;
}>({
  categories: null,
  register: undefined,
  setValue: undefined,
});

interface IFormInput {
  categories: string[];
}

export const useCategoriesContext = () => useContext(CategoriesContext);

const CategoriesContextProvider: FC<{ children: React.ReactNode }> = (
  props,
) => {
  const { setPaginationPage } = usePaginationPageContext();

  const {
    register,
    formState: { errors, isDirty },
    watch,
    setValue,
  } = useForm<IFormInput>({
    mode: "onSubmit",
    defaultValues: {},
  });
  const watchCategories = watch("categories");
  const categories = watchCategories?.map((el: any) => Number(el)) || [];

  const stringifiedWatch = JSON.stringify(watchCategories);
  useEffect(() => {
    setPaginationPage(1);
  }, [stringifiedWatch]);

  const contextValue = useMemo(() => {
    return {
      categories,
      register,
      setValue,
    };
  }, [register, categories, setValue]);

  return (
    <CategoriesContext.Provider value={contextValue}>
      {props.children}
    </CategoriesContext.Provider>
  );
};

export default CategoriesContextProvider;

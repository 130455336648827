import React, { FC } from "react";

const DButton: FC<{
  className: string;
  disabled: boolean;
  isLoading: boolean;
  name: string;
  onClick: (...args: any[]) => void;
  hideSpinner?: boolean;
}> = ({ className, disabled, isLoading, name, onClick, hideSpinner }) => {
  return (
    <button disabled={disabled} className={className} onClick={onClick}>
      {isLoading && !hideSpinner && (
        <span className="loading loading-spinner"></span>
      )}
      {name}
    </button>
  );
};

export default DButton;

// import AsyncStorage from "@react-native-async-storage/async-storage";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

type ColorSchemeName = "light" | "dark";

export interface LocalPreferencesSlice {
  language: string;
  languageSameAsDevice: boolean;
  theme: ColorSchemeName;
  themeSameAsDevice: boolean;
}

const initialState: LocalPreferencesSlice = {
  language: "en",
  languageSameAsDevice: true,
  theme: "light",
  themeSameAsDevice: true,
};

export const localPreferencesSlice = createSlice({
  name: "localpreferences",
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setLanguageSameAsDevice: (state, action: PayloadAction<boolean>) => {
      state.languageSameAsDevice = action.payload;
    },
    setTheme: (state, action: PayloadAction<ColorSchemeName>) => {
      state.theme = action.payload;
    },
    setThemeSameAsDevice: (state, action: PayloadAction<boolean>) => {
      state.themeSameAsDevice = action.payload;
    },
  },
});

export const {
  setLanguage,
  setLanguageSameAsDevice,
  setTheme,
  setThemeSameAsDevice,
} = localPreferencesSlice.actions;

const persistConfig = {
  key: "localPreferencesPersist",
  storage,
  // storage: AsyncStorage,
};
const persistedReducer = persistReducer(
  persistConfig,
  localPreferencesSlice.reducer,
);

export default persistedReducer;

// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/calendar
import { ResponsiveCalendar } from "@nivo/calendar";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERY_MAX_MOBILE_500 } from "../../constants/media-query";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const MyResponsiveCalendar = ({
  data,
  startDate,
  endDate,
}: {
  data: any;
  startDate: string;
  endDate: string;
}) => {
  const isMobile500 = useMediaQuery({
    query: `(max-width: ${MEDIA_QUERY_MAX_MOBILE_500}px)`,
  });

  return (
    <ResponsiveCalendar
      data={data}
      from={startDate} // "2015-03-01"
      to={endDate} // "2016-07-12"
      emptyColor="#eeeeee"
      colors={["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"]}
      margin={
        isMobile500
          ? { top: 20, right: 20, bottom: 20, left: 20 }
          : { top: 40, right: 40, bottom: 40, left: 40 }
      }
      yearSpacing={40}
      monthBorderColor="#ffffff"
      dayBorderWidth={1}
      dayBorderColor="#ffffff"
      legends={[
        {
          anchor: "bottom-right",
          direction: "row",
          translateY: 36,
          itemCount: 4,
          itemWidth: 42,
          itemHeight: 36,
          itemsSpacing: 14,
          itemDirection: "right-to-left",
        },
      ]}
    />
  );
};

export default MyResponsiveCalendar;

import React, { useEffect } from "react";
import { Layout } from "../../components/layout/layout";
import {
  useCategoriesControllerFindAllQuery,
  useNotesControllerFindOneQuery,
  useNotesControllerUpdateMutation,
} from "../../rtk/codegen/core-api";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { UpdateNoteParams } from "../../navigation/types";
import { IFormInput } from "../notes-post-page/interfaces/interfaces";
import NoteForm from "../notes-post-page/components/note-form";

export const NotesUpdatePage: React.FC = () => {
  const [notesControllerCreate, { isLoading: isLoadingUpdate, data, error }] =
    useNotesControllerUpdateMutation();

  const { id } = useParams<UpdateNoteParams>();

  const { data: dataCategories, isLoading: isLoadingCategories } =
    useCategoriesControllerFindAllQuery();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    control,
  } = useForm<IFormInput>({
    defaultValues: {
      title: "",
      body: "",
      categories: [],
      startDate: new Date(),
    },
  });

  const { data: dataFind, isLoading } = useNotesControllerFindOneQuery({
    id: Number(id),
  });
  const onSubmit: SubmitHandler<IFormInput> = async (values) => {
    try {
      await notesControllerCreate({
        id: Number(id),
        updateNoteDto: {
          title: values.title,
          body: values.body,
          categories: values.categories.map((el) => Number(el)),
          startDate: values.startDate.toISOString(),
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (dataFind) {
      reset({
        title: dataFind?.title,
        body: dataFind?.body,
        categories: dataFind?.categories.map((el) => el.id.toString()),
        startDate: new Date(dataFind?.startDate),
      });
    }
  }, [dataFind, reset]);

  return (
    <Layout small>
      <NoteForm
        isLoading={isLoadingUpdate}
        loading={isLoading || isLoadingCategories}
        update
        onSubmit={handleSubmit(onSubmit)}
        errors={errors}
        register={register}
        dataCategories={dataCategories}
        control={control}
        data={data}
        error={error}
        disabled={!isValid}
      />
    </Layout>
  );
};
